
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/skeletonGrids.scss';
@import '@bt-branding/colors';
@import '../../style/globalColorVars.scss';

.bt-login-form {
  box-shadow: 0px 0px 3px $box-shadow;
}

.bt-modal-form {
  .sc-layout-component-h .modal-wrapper.sc-layout-component {
    box-shadow: none;
    padding: 0;
  }
}

.skeleton-loading {
  margin: auto;
  max-width: 460px;
  width: 100%;
  padding: 30px 30px;
  background: $bt-white 0% 0% no-repeat padding-box;
  position: relative;

  .form-heading {
    background-color: $bt-tertiary;
    width: 30%;
    height: 30px;
    margin-bottom: 15px;
  }

  .form-sub-heading {
    background-color: $bt-tertiary;
    width: 75%;
    height: 24px;
  }

  .form-input {
    width: 100%;
    height: 53px;
    background-color: $bt-tertiary;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .login-form-button {
    width: 122px;
    height: 48px;
    background-color: $bt-tertiary;
    border-radius: 3px;
    margin-top: 30px;
  }
  .join-form-button {
    width: 100%;
    height: 48px;
    background-color: $bt-tertiary;
    border-radius: 3px;
    margin-top: 30px;
  }
  .terms-prompt {
    margin-top: 15px;

    p {
      background-color: $bt-tertiary;
      height: 15px;
      margin-bottom: 6px;
    }
    p:last-child {
      width: 30%;
    }
  }
}
